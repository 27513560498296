<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="show"
    max-width="500px"
    max-height="800px"
    scrollable
    persistent
  >
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-card background-color="#f2f2f2" v-else>
      <v-card-title
        class="py-0 pt-7"
        style="background-color: #7253cf; color: white"
      >
        <div class="d-flex px-1">
          <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
        </div>
        <div class="ml-1 dialogTitle-text">
          {{ dialogTitle }}
        </div>
        <v-spacer></v-spacer>
        <v-icon
          text
          large
          color="white"
          style="
            cursor: pointer;
            position: relative;
            right: -15px;
            bottom: 40px;
          "
          @click="toggleHomeTeamModal({ show: false })"
          >mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form lazy-validation ref="homeTeamForm">
          <v-row justify="center" no-gutters>
            <v-col cols="11">
              <v-text-field
                outlined
                dense
                v-model="name"
                label="Name"
                color="#7253CF"
                class="formFields"
                :rules="[rules.required]"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="11">
              <v-text-field
                outlined
                dense
                v-model="email"
                label="Email"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
                :rules="[rules.required, rules.email]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="11">
              <v-text-field
                outlined
                dense
                v-model="mobile"
                label="Mobile Number"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
                :rules="[rules.required, rules.mobile]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="11">
              <v-autocomplete
                label="T-shirt size"
                outlined
                dense
                v-model="tShirtSize"
                :items="tShirtSizeOptions"
                :rules="[rules.required]"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
                :readonly="type === 'view'"
              ></v-autocomplete>
            </v-col>
            <v-col cols="11">
              <v-text-field
                label="School/Organization"
                outlined
                dense
                v-model="school"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="11">
              <v-autocomplete
                label="School Type"
                outlined
                dense
                v-model="schoolType"
                :items="schoolTypeList"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="11">
              <v-text-field
                label="Teacher Type"
                outlined
                dense
                v-model="teacherType"
                class="formFields"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-text-field>
            </v-col>
            <v-col cols="11" class="d-flex justify-start text-left py-0">
              <v-switch
                style="margin-top: -5px"
                color="#067605"
                v-model="isConfirmed"
              >
                <template v-slot:label>
                  <div
                    v-if="isConfirmed"
                    style="color: #067605; font-size: 14px"
                  >
                    Member Confirmed
                  </div>
                  <div v-else style="font-size: 14px; color: #8c8c8c">
                    Member Not Confirmed
                  </div>
                </template>
              </v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="dialogAction-btnText text-capitalize px-7"
          height="45"
          color="#38227A"
          :loading="formLoading"
          @click="submitForm"
        >
          <span>{{ actionText }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import Axios from "@/api/BaseAxios";
// import { ROUTER_URL } from "@/constants/urls";
import {
  API_ADMIN_GET_SCHOOL_LIST,
  API_HOME_TEAM_POST,
  API_HOME_TEAM_PATCH,
  API_HOME_TEAM_GET,
} from "@/constants/APIUrls";
export default {
  name: "HomeTeamModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      name: "",
      email: "",
      mobile: null,
      tShirtSize: "",
      school: "",
      schoolType: "",
      schoolTypeList: ["Elementary School", "Middle School", "High School"],
      schoolList: [],
      tShirtSizeOptions: [
        "Extra Small",
        "Small",
        "Medium",
        "Large",
        "Extra Large",
        "Adult Small",
        "Adult Medium",
      ],
      teacherType: "",
      isConfirmed: true,
      teacherTypeList: [
        { name: "GENERAL", value: "general" },
        { name: "PE(Physical Education)", value: "pe" },
        { name: "PRINCIPAL", value: "principal" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getShow: "gameManagement/getTeamShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.toggleHomeTeamModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.homeTeamModal.type;
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add HomeTeam Member";
        case "edit":
          return "Update Member Detail";
        default:
          return "";
      }
    },
    actionText() {
      if (this.type === "add") {
        return "Add Member";
      } else {
        return "Save";
      }
    },
    toastMessage() {
      if (this.type === "add") {
        return "Added Successfully";
      } else {
        return "Updated Successfully";
      }
    },
  },
  watch: {
    show(value) {
      if (value) this.openModal();
      else this.closeModal();
    },
  },
  methods: {
    ...mapActions({
      toggleHomeTeamModal: "gameManagement/toggleHomeTeam",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      if (this.type === "edit") {
        this.getDetail();
      }
    },
    closeModal() {
      this.loading = false;
      this.formLoading = false;
      this.name = "";
      this.email = "";
      this.mobile = null;
      this.tShirtSize = "";
      this.school = "";
      this.schoolType = "";
      this.schoolList = [];
      this.isConfirmed = true;
    },
    getSchool() {
      this.loading = true;
      const successHandler = (res) => {
        this.schoolList = res.data.school_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        if (this.type === "edit") {
          this.getDetail();
        } else {
          this.loading = false;
        }
      };
      let formData = {};
      if (this.district) {
        formData["district_id"] = this.district;
      }
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getDetail() {
      const successHandler = (res) => {
        this.name = res.data.home_team_detail.name;
        this.email = res.data.home_team_detail.email;
        this.mobile = res.data.home_team_detail.phone;
        this.tShirtSize = res.data.home_team_detail.tshirt_size;
        this.school = res.data.home_team_detail.school;
        this.teacherType = res.data.home_team_detail.teacher;
        this.isConfirmed = res.data.home_team_detail.is_confirmed;
        this.schoolType=res.data.home_team_detail.school_type;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["home_team_id"] =
        this.$store.state.gameManagement.homeTeamModal.teamID;
      Axios.request_GET(
        API_HOME_TEAM_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      if (this.$refs.homeTeamForm.validate()) {
        this.formLoading = true;
        const successHandler = (res) => {
          console.log(res);
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
          this.formLoading = false;
          this.toggleHomeTeamModal({ show: false });
          this.$emit("reload");
        };
        const failureHandler = (res) => {
          console.log(res);
          this.showToast({
            message: res,
            color: "e",
          });
          this.formLoading = false;
        };
        let formData = {};
        formData["game"] = this.$route.query.game_id;
        formData["name"] = this.name;
        formData["email"] = this.email;
        formData["phone"] = this.mobile;
        formData["tshirt_size"] = this.tShirtSize;
        formData["school"] = this.school;
        formData["school_type"] = this.schoolType;
        formData["is_confirmed"] = this.isConfirmed;
        formData["teacher"] = this.teacherType;
        if (this.type === "add") {
          Axios.request_POST(
            API_HOME_TEAM_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          formData["id"] =
            this.$store.state.gameManagement.homeTeamModal.teamID;
          Axios.request_PATCH(
            API_HOME_TEAM_PATCH,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 20px;
}
img.logo {
  margin-left: 20px;
  max-height: 80px;
}
.file-input-col {
  margin-left: 20px;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
